<template>
  <small>{{ priceOne }}</small>
</template>

<script lang="ts" setup>
import { defineComponent, toRefs, computed } from 'vue'
import { useLocalization } from '#imports'
import { useConfig } from '@/stores/config'

defineComponent({ name: 'APriceone' })

const props = defineProps({
  price: {
    type: [String, Number],
    required: true
  },
  currency: {
    type: String,
    default: 'RUB'
  }
})

const { price, currency } = toRefs(props)
const { locale, t } = useLocalization()

const { currentRegion } = useConfig()

const currencyFormat = new Intl.NumberFormat(
  locale.value, { style: 'currency', currency: currentRegion.value.defaultCurrency, minimumFractionDigits: 0 }
)

const priceOne = computed(() => {
  let currentPrice
  if (currentRegion.value?.defaultCurrency?.toLowerCase() !== currency.value?.toLowerCase()) {
    currentPrice = new Intl.NumberFormat(
      locale.value,
      { style: 'currency', currency: currency.value, minimumFractionDigits: 0 }
    ).format(+price.value)
  } else {
    currentPrice = currencyFormat.format(+price.value)
  }

  return `${currentPrice} / ${t('atoms.priceone.unit')}`
})
</script>

<style lang="postcss">
small {
  display: inline-block;
  color: var(--color-neutral);

  @mixin text-sm;
}
</style>
